import exercise from './exercise.gif'
import exercise2 from './exercise_2.gif'
import exercise3 from './exercise_3.gif'
import exercise4 from './exercise_4.gif'

const Arms = ({ pullups }) => {
  let giff = exercise;
  if (pullups % 4 === 1) {
    giff = exercise2;
  }
  if (pullups % 4 === 2) {
    giff = exercise3;
  }
  if (pullups % 4 === 3) {
    giff = exercise4;
  }
  return (
    <>
      <img src={giff} className="App-logo" alt="challange-motivation" />
      <p>
        Heute sind's {pullups} Klimmzüge 💪
      </p>
    </>
  )
}

export default Arms;
