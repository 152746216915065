import exercise from './exausted.gif'
import Fireworks from "@fireworks-js/react"

const Pause = ({ }) => (
  <>
    <Fireworks options={{ opacity: 0.5 }}  style={{top: 0, left: 0, width: '100%', height: '100%', position: 'fixed'}} />
      <img src={exercise} className="App-logo" alt="challange-motivation" />
      <p>
        50 Klimmzüge Challenge gemeistert 🥳🥳
      </p>
  </>
)

export default Pause;
