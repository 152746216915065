import './App.css';

import Arms from "./Arms"
import Pause from "./Pause"

function App() {
  const oneDay = 24 * 60 * 60 * 1000;
  const today = new Date();
  const start = Date.parse("2024-02-01T00:00");
  const days = Math.floor((today - start) / oneDay) + 25;
  if (days >= 50) {
  return (
    <div className="App">
      <header className="App-header">
        <Pause />
      </header>
    </div>
  );
  }
  return (
    <div className="App">
      <header className="App-header">
        <Arms pullups={days}/>
      </header>
    </div>
  );
}

export default App;
